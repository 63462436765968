import { Checkbox } from '@material-ui/core';
import _ from 'lodash';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IArgs {
  dataSource: any[];
  withCheckbox: boolean;
  isShared?: boolean;
  onSelectedRowsChange?: (nextSelectedRows: any) => void;
}

const GUEST_USER_ID = 1;

const useCheckbox = ({ dataSource, onSelectedRowsChange, isShared, withCheckbox }: IArgs) => {
  const { t } = useTranslation();
  const [selectedRowsArray, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const emptyText = useMemo(() => t('common.empty'), [t]);

  const guestUsersCount = useMemo(() => dataSource.filter((u) => u.id === GUEST_USER_ID).length, [dataSource]);

  const getFilteredUsersIdsUnderGroup = useCallback(
    ({ fieldPath, keyPath }) =>
      dataSource
        .filter((u) =>
          fieldPath?.every((data, index) =>
            keyPath[index] === emptyText ? [undefined, '', null].includes(u[data]) : u[data] === keyPath[index],
          ),
        )
        .map((u) => u.id),
    [emptyText, dataSource],
  );

  // maintain this for fast object keys based lookups
  const selectedRows = useMemo(
    () => selectedRowsArray.reduce((acc, row) => ({ ...acc, [row.id]: row }), {}),
    [selectedRowsArray],
  );

  const areAllFilteredUsersUnderGroupSelected = ({ filteredUsersIdsUnderGroup }) =>
    filteredUsersIdsUnderGroup?.every((uid) => uid in selectedRows);

  const arePartFilteredUsersUnderGroupSelected = ({ filteredUsersIdsUnderGroup }) =>
    filteredUsersIdsUnderGroup?.some((uid) => uid in selectedRows);

  useEffect(() => {
    setSelectedRows([]);
  }, [isShared]);

  const updateSelectedRows = useCallback(
    ({ ids, areAllSelected, isSingle }: { ids: number[]; areAllSelected: boolean; isSingle: boolean }) => {
      let nextSelectedRowsArray = [...selectedRowsArray];
      ids.forEach((uid) => {
        if (isSingle && uid === GUEST_USER_ID) {
          if (areAllSelected && nextSelectedRowsArray.find((row) => row.id === GUEST_USER_ID)) {
            nextSelectedRowsArray = nextSelectedRowsArray.filter((row) => row.id !== GUEST_USER_ID);
          } else {
            for (let i = 0; i < guestUsersCount; i++) {
              // fill up all the gust users to allow "all selected" checkbox to work
              nextSelectedRowsArray.push({ id: uid });
            }
          }
        } else {
          const indexInArray = nextSelectedRowsArray.findIndex((row) => row.id === uid);
          if (indexInArray !== -1 && areAllSelected) {
            nextSelectedRowsArray.splice(indexInArray, 1);
          } else {
            nextSelectedRowsArray.push({ id: uid });
          }
        }
      });
      return nextSelectedRowsArray;
    },
    [guestUsersCount, selectedRowsArray],
  );

  const toggleCheckboxGroup = ({ fieldPath, keyPath }) => {
    const filteredUsersIdsUnderGroup = getFilteredUsersIdsUnderGroup({
      fieldPath,
      keyPath,
    });
    const areAllSelected = areAllFilteredUsersUnderGroupSelected({
      filteredUsersIdsUnderGroup,
    });

    const nextSelectedRowsArray = updateSelectedRows({
      ids: filteredUsersIdsUnderGroup,
      areAllSelected,
      isSingle: false,
    });

    setSelectedRows(nextSelectedRowsArray);
    onSelectedRowsChange?.(nextSelectedRowsArray);
  };

  const toggleCheckboxItem = useCallback(
    (userId) => {
      const nextSelectedRowsArray = updateSelectedRows({
        ids: [userId],
        areAllSelected: true,
        isSingle: true,
      });

      setSelectedRows(nextSelectedRowsArray);
      onSelectedRowsChange?.(nextSelectedRowsArray);
    },
    [updateSelectedRows, onSelectedRowsChange],
  );

  const CheckboxColumn = ({ data }) => {
    const { id, __group, fieldPath, keyPath } = data;
    return (
      <Checkbox
        color='primary'
        style={{ padding: 0 }}
        checked={
          __group
            ? areAllFilteredUsersUnderGroupSelected({
                filteredUsersIdsUnderGroup: getFilteredUsersIdsUnderGroup({
                  fieldPath,
                  keyPath,
                }),
              })
            : !!selectedRows[id]
        }
        indeterminate={
          arePartFilteredUsersUnderGroupSelected({
            filteredUsersIdsUnderGroup: getFilteredUsersIdsUnderGroup({
              fieldPath,
              keyPath,
            }),
          }) &&
          !areAllFilteredUsersUnderGroupSelected({
            filteredUsersIdsUnderGroup: getFilteredUsersIdsUnderGroup({
              fieldPath,
              keyPath,
            }),
          })
        }
        onChange={() => (__group ? toggleCheckboxGroup({ fieldPath, keyPath }) : toggleCheckboxItem(id))}
      />
    );
  };

  const CheckboxHeader = ({ onChange, checked }) => (
    <Checkbox style={{ padding: 0, color: '#fff' }} color='primary' checked={checked} onChange={onChange} />
  );

  const checkboxColumn = withCheckbox
    ? {
        render: CheckboxColumn,
        renderCheckbox: CheckboxHeader,
      }
    : null;

  const handleSelectAll = ({ selected }) => {
    if (isAllSelected && _.isEmpty(selected)) {
      setSelectedRows([]);
      setIsAllSelected(false);
      return;
    }

    const allRows = dataSource.reduce((acc, row) => {
      if (row.__group) {
        const children = row.__children || [];
        children.forEach((child) => {
          acc.push(child);
        });
      } else {
        acc.push(row);
      }
      return acc;
    }, []);

    setSelectedRows(allRows);
    setIsAllSelected(true);
  };

  return { checkboxColumn, selectedRowsArray, handleSelectAll };
};

export default useCheckbox;
